/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StopPageContentContainer_stop$ref = any;
export type stopRoutes_StopPageContent_QueryVariables = {|
  stopId: string
|};
export type stopRoutes_StopPageContent_QueryResponse = {|
  +stop: ?{|
    +$fragmentRefs: StopPageContentContainer_stop$ref
  |}
|};
export type stopRoutes_StopPageContent_Query = {|
  variables: stopRoutes_StopPageContent_QueryVariables,
  response: stopRoutes_StopPageContent_QueryResponse,
|};
*/


/*
query stopRoutes_StopPageContent_Query(
  $stopId: String!
) {
  stop(id: $stopId) {
    ...StopPageContentContainer_stop
    id
  }
}

fragment DepartureListContainer_stoptimes on Stoptime {
  realtimeState
  realtimeDeparture
  scheduledDeparture
  realtimeArrival
  scheduledArrival
  realtime
  serviceDay
  pickupType
  headsign
  stop {
    id
    code
    platformCode
  }
  trip {
    gtfsId
    directionId
    tripHeadsign
    stops {
      id
    }
    pattern {
      route {
        gtfsId
        shortName
        longName
        mode
        color
        agency {
          name
          id
        }
        alerts {
          alertSeverityLevel
          effectiveEndDate
          effectiveStartDate
          trip {
            pattern {
              code
              id
            }
            id
          }
          id
        }
        id
      }
      code
      stops {
        gtfsId
        code
        id
      }
      id
    }
    id
  }
}

fragment StopPageContentContainer_stop on Stop {
  url
  stoptimes: stoptimesWithoutPatterns(startTime: 0, timeRange: 864000, numberOfDepartures: 100, omitCanceled: false) {
    ...DepartureListContainer_stoptimes
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "stopId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "stopId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gtfsId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "stopRoutes_StopPageContent_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "stop",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StopPageContentContainer_stop"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "stopRoutes_StopPageContent_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "stop",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": "stoptimes",
            "args": [
              {
                "kind": "Literal",
                "name": "numberOfDepartures",
                "value": 100
              },
              {
                "kind": "Literal",
                "name": "omitCanceled",
                "value": false
              },
              {
                "kind": "Literal",
                "name": "startTime",
                "value": 0
              },
              {
                "kind": "Literal",
                "name": "timeRange",
                "value": 864000
              }
            ],
            "concreteType": "Stoptime",
            "kind": "LinkedField",
            "name": "stoptimesWithoutPatterns",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "realtimeState",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "realtimeDeparture",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduledDeparture",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "realtimeArrival",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduledArrival",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "realtime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serviceDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pickupType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "headsign",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Stop",
                "kind": "LinkedField",
                "name": "stop",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "platformCode",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Trip",
                "kind": "LinkedField",
                "name": "trip",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "directionId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tripHeadsign",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Stop",
                    "kind": "LinkedField",
                    "name": "stops",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Pattern",
                    "kind": "LinkedField",
                    "name": "pattern",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Route",
                        "kind": "LinkedField",
                        "name": "route",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shortName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "longName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "mode",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "color",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Agency",
                            "kind": "LinkedField",
                            "name": "agency",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Alert",
                            "kind": "LinkedField",
                            "name": "alerts",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "alertSeverityLevel",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "effectiveEndDate",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "effectiveStartDate",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Trip",
                                "kind": "LinkedField",
                                "name": "trip",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Pattern",
                                    "kind": "LinkedField",
                                    "name": "pattern",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Stop",
                        "kind": "LinkedField",
                        "name": "stops",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "stoptimesWithoutPatterns(numberOfDepartures:100,omitCanceled:false,startTime:0,timeRange:864000)"
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9f68810c9a7a4f3799a094da64aa10e0",
    "id": null,
    "metadata": {},
    "name": "stopRoutes_StopPageContent_Query",
    "operationKind": "query",
    "text": "query stopRoutes_StopPageContent_Query(\n  $stopId: String!\n) {\n  stop(id: $stopId) {\n    ...StopPageContentContainer_stop\n    id\n  }\n}\n\nfragment DepartureListContainer_stoptimes on Stoptime {\n  realtimeState\n  realtimeDeparture\n  scheduledDeparture\n  realtimeArrival\n  scheduledArrival\n  realtime\n  serviceDay\n  pickupType\n  headsign\n  stop {\n    id\n    code\n    platformCode\n  }\n  trip {\n    gtfsId\n    directionId\n    tripHeadsign\n    stops {\n      id\n    }\n    pattern {\n      route {\n        gtfsId\n        shortName\n        longName\n        mode\n        color\n        agency {\n          name\n          id\n        }\n        alerts {\n          alertSeverityLevel\n          effectiveEndDate\n          effectiveStartDate\n          trip {\n            pattern {\n              code\n              id\n            }\n            id\n          }\n          id\n        }\n        id\n      }\n      code\n      stops {\n        gtfsId\n        code\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment StopPageContentContainer_stop on Stop {\n  url\n  stoptimes: stoptimesWithoutPatterns(startTime: 0, timeRange: 864000, numberOfDepartures: 100, omitCanceled: false) {\n    ...DepartureListContainer_stoptimes\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '12a4133fb1bc58a53f04d90d1896cc32';

module.exports = node;
