/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RouteAlertsContainer_route$ref = any;
export type routeRoutes_RouteAlertsContainer_QueryVariables = {|
  routeId: string,
  date: string,
|};
export type routeRoutes_RouteAlertsContainer_QueryResponse = {|
  +route: ?{|
    +$fragmentRefs: RouteAlertsContainer_route$ref
  |}
|};
export type routeRoutes_RouteAlertsContainer_Query = {|
  variables: routeRoutes_RouteAlertsContainer_QueryVariables,
  response: routeRoutes_RouteAlertsContainer_QueryResponse,
|};
*/


/*
query routeRoutes_RouteAlertsContainer_Query(
  $routeId: String!
  $date: String!
) {
  route(id: $routeId) {
    ...RouteAlertsContainer_route_19b1FI
    id
  }
}

fragment RouteAlertsContainer_route_19b1FI on Route {
  color
  mode
  shortName
  alerts {
    id
    alertDescriptionText
    alertHash
    alertHeaderText
    alertSeverityLevel
    alertUrl
    effectiveEndDate
    effectiveStartDate
    alertDescriptionTextTranslations {
      language
      text
    }
    alertHeaderTextTranslations {
      language
      text
    }
    alertUrlTranslations {
      language
      text
    }
    trip {
      pattern {
        code
        id
      }
      id
    }
  }
  patterns {
    code
    stops {
      id
      gtfsId
      code
      name
      alerts {
        id
        alertDescriptionText
        alertHash
        alertHeaderText
        alertSeverityLevel
        alertUrl
        effectiveEndDate
        effectiveStartDate
        alertDescriptionTextTranslations {
          language
          text
        }
        alertHeaderTextTranslations {
          language
          text
        }
        alertUrlTranslations {
          language
          text
        }
      }
    }
    trips: tripsForDate(serviceDate: $date) {
      tripHeadsign
      stoptimes: stoptimesForDate(serviceDate: $date) {
        headsign
        realtimeState
        scheduledArrival
        scheduledDeparture
        serviceDay
        stop {
          name
          id
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "routeId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "routeId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "alertDescriptionText",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "alertHash",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "alertHeaderText",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "alertSeverityLevel",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "alertUrl",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "effectiveEndDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "effectiveStartDate",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "language",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "TranslatedString",
  "kind": "LinkedField",
  "name": "alertDescriptionTextTranslations",
  "plural": true,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "TranslatedString",
  "kind": "LinkedField",
  "name": "alertHeaderTextTranslations",
  "plural": true,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "TranslatedString",
  "kind": "LinkedField",
  "name": "alertUrlTranslations",
  "plural": true,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v17 = [
  {
    "kind": "Variable",
    "name": "serviceDate",
    "variableName": "date"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "routeRoutes_RouteAlertsContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Route",
        "kind": "LinkedField",
        "name": "route",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "date",
                "variableName": "date"
              }
            ],
            "kind": "FragmentSpread",
            "name": "RouteAlertsContainer_route"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "routeRoutes_RouteAlertsContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Route",
        "kind": "LinkedField",
        "name": "route",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shortName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Alert",
            "kind": "LinkedField",
            "name": "alerts",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Trip",
                "kind": "LinkedField",
                "name": "trip",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Pattern",
                    "kind": "LinkedField",
                    "name": "pattern",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pattern",
            "kind": "LinkedField",
            "name": "patterns",
            "plural": true,
            "selections": [
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Stop",
                "kind": "LinkedField",
                "name": "stops",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gtfsId",
                    "storageKey": null
                  },
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Alert",
                    "kind": "LinkedField",
                    "name": "alerts",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "trips",
                "args": (v17/*: any*/),
                "concreteType": "Trip",
                "kind": "LinkedField",
                "name": "tripsForDate",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tripHeadsign",
                    "storageKey": null
                  },
                  {
                    "alias": "stoptimes",
                    "args": (v17/*: any*/),
                    "concreteType": "Stoptime",
                    "kind": "LinkedField",
                    "name": "stoptimesForDate",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "headsign",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "realtimeState",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "scheduledArrival",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "scheduledDeparture",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "serviceDay",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Stop",
                        "kind": "LinkedField",
                        "name": "stop",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ebb2d76e985dd976bac95026000a20a0",
    "id": null,
    "metadata": {},
    "name": "routeRoutes_RouteAlertsContainer_Query",
    "operationKind": "query",
    "text": "query routeRoutes_RouteAlertsContainer_Query(\n  $routeId: String!\n  $date: String!\n) {\n  route(id: $routeId) {\n    ...RouteAlertsContainer_route_19b1FI\n    id\n  }\n}\n\nfragment RouteAlertsContainer_route_19b1FI on Route {\n  color\n  mode\n  shortName\n  alerts {\n    id\n    alertDescriptionText\n    alertHash\n    alertHeaderText\n    alertSeverityLevel\n    alertUrl\n    effectiveEndDate\n    effectiveStartDate\n    alertDescriptionTextTranslations {\n      language\n      text\n    }\n    alertHeaderTextTranslations {\n      language\n      text\n    }\n    alertUrlTranslations {\n      language\n      text\n    }\n    trip {\n      pattern {\n        code\n        id\n      }\n      id\n    }\n  }\n  patterns {\n    code\n    stops {\n      id\n      gtfsId\n      code\n      name\n      alerts {\n        id\n        alertDescriptionText\n        alertHash\n        alertHeaderText\n        alertSeverityLevel\n        alertUrl\n        effectiveEndDate\n        effectiveStartDate\n        alertDescriptionTextTranslations {\n          language\n          text\n        }\n        alertHeaderTextTranslations {\n          language\n          text\n        }\n        alertUrlTranslations {\n          language\n          text\n        }\n      }\n    }\n    trips: tripsForDate(serviceDate: $date) {\n      tripHeadsign\n      stoptimes: stoptimesForDate(serviceDate: $date) {\n        headsign\n        realtimeState\n        scheduledArrival\n        scheduledDeparture\n        serviceDay\n        stop {\n          name\n          id\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f732609f154b4a7b31263b441b1694d2';

module.exports = node;
