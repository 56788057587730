/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DynamicParkingLotsContent_vehicleParking$ref = any;
export type routes_DynamicParkingLot_QueryVariables = {|
  id: string
|};
export type routes_DynamicParkingLot_QueryResponse = {|
  +vehicleParking: ?{|
    +$fragmentRefs: DynamicParkingLotsContent_vehicleParking$ref
  |}
|};
export type routes_DynamicParkingLot_Query = {|
  variables: routes_DynamicParkingLot_QueryVariables,
  response: routes_DynamicParkingLot_QueryResponse,
|};
*/


/*
query routes_DynamicParkingLot_Query(
  $id: String!
) {
  vehicleParking(id: $id) {
    ...DynamicParkingLotsContent_vehicleParking
    id
  }
}

fragment DynamicParkingLotsContent_vehicleParking on VehicleParking {
  vehicleParkingId
  name
  lon
  lat
  capacity {
    carSpaces
    wheelchairAccessibleCarSpaces
  }
  availability {
    carSpaces
    wheelchairAccessibleCarSpaces
  }
  tags
  note
  detailsUrl
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "carSpaces",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "wheelchairAccessibleCarSpaces",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_DynamicParkingLot_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VehicleParking",
        "kind": "LinkedField",
        "name": "vehicleParking",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DynamicParkingLotsContent_vehicleParking"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_DynamicParkingLot_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VehicleParking",
        "kind": "LinkedField",
        "name": "vehicleParking",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "vehicleParkingId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lon",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "VehicleParkingSpaces",
            "kind": "LinkedField",
            "name": "capacity",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "VehicleParkingSpaces",
            "kind": "LinkedField",
            "name": "availability",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tags",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "note",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "detailsUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "63e11e35c9563c1dae1680669270dfb5",
    "id": null,
    "metadata": {},
    "name": "routes_DynamicParkingLot_Query",
    "operationKind": "query",
    "text": "query routes_DynamicParkingLot_Query(\n  $id: String!\n) {\n  vehicleParking(id: $id) {\n    ...DynamicParkingLotsContent_vehicleParking\n    id\n  }\n}\n\nfragment DynamicParkingLotsContent_vehicleParking on VehicleParking {\n  vehicleParkingId\n  name\n  lon\n  lat\n  capacity {\n    carSpaces\n    wheelchairAccessibleCarSpaces\n  }\n  availability {\n    carSpaces\n    wheelchairAccessibleCarSpaces\n  }\n  tags\n  note\n  detailsUrl\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c0933dee7e5d85e564be068eceaed569';

module.exports = node;
