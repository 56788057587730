/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StopPageMapContainer_stop$ref = any;
export type stopRoutes_StopPageMapContainer_QueryVariables = {|
  stopId: string
|};
export type stopRoutes_StopPageMapContainer_QueryResponse = {|
  +stop: ?{|
    +$fragmentRefs: StopPageMapContainer_stop$ref
  |}
|};
export type stopRoutes_StopPageMapContainer_Query = {|
  variables: stopRoutes_StopPageMapContainer_QueryVariables,
  response: stopRoutes_StopPageMapContainer_QueryResponse,
|};
*/


/*
query stopRoutes_StopPageMapContainer_Query(
  $stopId: String!
) {
  stop(id: $stopId) {
    ...StopPageMapContainer_stop
    id
  }
}

fragment StopPageMapContainer_stop on Stop {
  lat
  lon
  platformCode
  name
  code
  desc
  vehicleMode
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "stopId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "stopId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "stopRoutes_StopPageMapContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "stop",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StopPageMapContainer_stop"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "stopRoutes_StopPageMapContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "stop",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lon",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "platformCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "desc",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "vehicleMode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d54f7956fc1fe967fcc3af63cc93917c",
    "id": null,
    "metadata": {},
    "name": "stopRoutes_StopPageMapContainer_Query",
    "operationKind": "query",
    "text": "query stopRoutes_StopPageMapContainer_Query(\n  $stopId: String!\n) {\n  stop(id: $stopId) {\n    ...StopPageMapContainer_stop\n    id\n  }\n}\n\nfragment StopPageMapContainer_stop on Stop {\n  lat\n  lon\n  platformCode\n  name\n  code\n  desc\n  vehicleMode\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '81932d8b6194aeac5b7f9bd8a47cce2f';

module.exports = node;
